// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kh09p7JO1oJ6k5hzIajx {\n    table-layout: fixed;\n    overflow-wrap: break-word;\n}\n", "",{"version":3,"sources":["webpack://./../graylog2-server/graylog2-web-interface/src/components/event-notifications/event-notification-types/CommonNotificationSummary.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,yBAAyB;AAC7B","sourcesContent":[":local(.fixedTable) {\n    table-layout: fixed;\n    overflow-wrap: break-word;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fixedTable": "kh09p7JO1oJ6k5hzIajx"
};
export default ___CSS_LOADER_EXPORT___;
